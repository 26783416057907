import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaFacebook, FaTiktok } from 'react-icons/fa'; // Importa la icona de TikTok

const FooterContainer = styled.div`
  background-color: #111;
  color: white;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const SocialLinks = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const SocialIcon = styled.a`
  color: white;
  font-size: 2em;
  transition: color 0.3s;

  &:hover {
    color: #ddd;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const LocationText = styled.a`
  margin-top: 20px;
  font-size: 1.2em;
  color: white;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #ddd;
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const FooterText = styled.div`
  margin-top: 10px;
  font-size: 1.2em;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialLinks>
        <SocialIcon href="https://www.instagram.com/royale_teatre/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </SocialIcon>
        <SocialIcon href="https://www.facebook.com/RoyaleSala/" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </SocialIcon>
        <SocialIcon href="https://www.tiktok.com/@royaleteatre" target="_blank" rel="noopener noreferrer">
          <FaTiktok />
        </SocialIcon>
      </SocialLinks>

      <LocationText
        href="https://www.google.com/maps/place/Teatre+Royale/@41.3454172,1.6961262,17z/data=!3m1!4b1!4m6!3m5!1s0x12a4798ba4c06621:0xaac46e689f076882!8m2!3d41.3454132!4d1.6987011!16s%2Fg%2F11ckvfk4kr?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>Rambla de Sant Francesc, 25</p>
        <p>08720 Vilafranca del Penedès, Barcelona</p>
      </LocationText>

      <FooterText>
        Royale Vilafranca | Tel: 600 55 55 33
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
