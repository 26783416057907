import React from 'react';
import styled from 'styled-components';

const Button = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px 25px;
  font-size: 18px;
  color: white;
  background-color: #25d366;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  z-index: 1000;

  &:hover {
    background-color: #1ebe57;
  }

  @media (max-width: 768px) { /* Dispositius mòbils */
    padding: 10px 15px; /* Botó més petit */
    font-size: 14px; /* Font més petita */
  }

  @media (min-width: 768px) {
    padding: 20px 30px;
    font-size: 24px;
  }
`;

const VipButton = ({ phoneNumber }) => {
  return (
    <Button href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
      WHATSAPP INFO I VIPS
    </Button>
  );
};

export default VipButton;
