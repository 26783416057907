import React from 'react';
import styled from 'styled-components';
import fallbackImage from '../assets/5.jpg'; // Imatge de fons com a substitut del vídeo

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background: url(${fallbackImage}) no-repeat center center/cover; /* Fallback per a dispositius mòbils */
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  filter: brightness(0.5); /* Fosc amb un 50% de transparència */
`;

const VideoBackground = ({ videoSrc }) => {
  return (
    <VideoContainer>
      <StyledVideo autoPlay loop muted playsInline>
        <source src={videoSrc} type="video/mp4" />
      </StyledVideo>
    </VideoContainer>
  );
};

export default VideoBackground;
