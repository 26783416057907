import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import VideoBackground from './components/VideoBackground';
import VipButton from './components/VipButton';
import Gallery from './components/Gallery';
import Fourvenues from './components/Fourvenues';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import styled from 'styled-components';
import videoSrc from './assets/royale2024.mp4';
import logo from './assets/LOGO ROYALE BLANCO.png';
import image1 from './assets/image1.jpg';
import image2 from './assets/2.jpg';
import image3 from './assets/3.jpg';
import image4 from './assets/4.jpg';
import image6 from './assets/6.jpg';
import image7 from './assets/7.jpg';
import image5 from './assets/5.jpg';
import image8 from './assets/8.jpg';
import image9 from './assets/9.jpg';
import image10 from './assets/10.jpg';
import image11 from './assets/11.jpg';
import image12 from './assets/12.jpg';
import image13 from './assets/13.jpg';
import image14 from './assets/14.jpg';
import image15 from './assets/15.jpg';
import image16 from './assets/16.jpg';
import image17 from './assets/17.jpg';
import image18 from './assets/18.jpg';
import image19 from './assets/19.jpg';
import image20 from './assets/20.jpg';
import image21 from './assets/21.jpg';
import image22 from './assets/22.jpg';
import image23 from './assets/23.jpg';
import image24 from './assets/24.jpg';
import image25 from './assets/image5.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh; /* Cambiat height per min-height per adaptar-se al contingut */
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Mantingut per a l'alçada del logo */
  margin-top: 15vh;

  @media (max-width: 768px) { /* Dispositius mòbils */
    margin-top: 0vh; /* Moure el logo més amunt */
    margin-bottom: 0vh; /* Espai inferior per a millorar la visualització */
  }
`;

const Logo = styled.img`
  max-width: 80%;
  height: auto;
  max-height: 80vh;
`;

const EventText = styled.h2`
  color: white;
  font-size: 3vh;
  margin-top: 5vh;
  text-align: center;

  @media (max-width: 768px) { /* Dispositius mòbils */
    margin-top: 0vh; /* Fer que el text "PRÒXIMS ESDEVENIMENTS" pugi una mica */
  }
`;

const FourvenuesContainer = styled.div`
  width: 100%;
  margin-bottom: 1vh; /* Espai sota el component per evitar solapament */

  @media (max-width: 768px) { /* Dispositius mòbils */
    margin-top: 0vh; /* Ajusta l'espai per millorar la visualització */
  }
`;

const ContentSection = styled.div`
  margin-top: 5vh;
  margin-bottom: 0vh;
  padding: 0 0px;
`;

function App() {
  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25];

  return (
    <Container>
      <VideoBackground videoSrc={videoSrc} />
      <LogoContainer>
        <Logo src={logo} alt="Royale Logo" />
      </LogoContainer>
      <EventText>PROPERS ESDEVENIMENTS:</EventText>
      <FourvenuesContainer>
        <Fourvenues />
      </FourvenuesContainer>
      <ContentSection>
        <Gallery images={images} />
      </ContentSection>
      <ContactForm />
      <Footer /> {/* Assegurem que el Footer sempre tingui un amplada del 100% */}
      <VipButton phoneNumber="34600555533" />
      <Analytics />
    </Container>
  );
}

export default App;
