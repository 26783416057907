import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

Modal.setAppElement('#root');

const GalleryContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  overflow-x: auto;
  padding: 20px;
  margin: 0 auto;
  max-width: 100%;
  white-space: nowrap;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
  }

  /* Ajust per a pantalles mòbils per assegurar-se que el desplaçament horitzontal funciona bé */
  @media (max-width: 767px) {
    padding-left: 5px; /* Evita que la primera imatge estigui tallada */
  }
`;

const ImageWrapper = styled.div`
  display: inline-block;
  margin: 15px;
  border-radius: 15px;
  overflow: hidden;
  flex: 0 0 auto;
  width: calc(100% / 5 - 30px);

  @media (min-width: 768px) {
    width: calc(100% / 4 - 30px);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 5 - 30px);
  }

  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  /* Afegeix aquest media query per millorar la visualització en mòbils */
  @media (max-width: 767px) {
    width: calc(100% / 2 - 20px); /* Mostra 2 imatges per fila sense ser retallades */
    margin: 10px; /* Ajusta els marges per evitar retallades */
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover; /* Assegura que la imatge s'ajusta dins del contenidor */
  border-radius: 15px;
  cursor: pointer;

  /* Ajust per a pantalles mòbils per assegurar que la imatge s'adapti sense retallar */
  @media (max-width: 767px) {
    object-fit: contain; /* Canvia a "contain" perquè la imatge s'ajusti dins del contenidor sense ser retallada */
  }
`;

const ModalImage = styled.img`
  width: 90%;
  height: auto;
  max-width: 1000px;
  border-radius: 10px;
  cursor: pointer; /* Canvia el cursor per indicar que es pot clicar */
`;

const Gallery = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleImageClick = () => {
    closeModal();
  };

  return (
    <div>
      <GalleryContainer>
        {images.map((img, index) => (
          <ImageWrapper key={index}>
            <Image
              src={img}
              alt={`Royale Image ${index + 1}`}
              onClick={() => openModal(img)}
            />
          </ImageWrapper>
        ))}
      </GalleryContainer>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            border: 'none',
            padding: '0',
            inset: '0',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        {selectedImage && (
          <ModalImage
            src={selectedImage}
            alt="Selected"
            onClick={handleImageClick}
          />
        )}
      </Modal>
    </div>
  );
};

export default Gallery;
