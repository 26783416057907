import React, { useEffect } from 'react';
import styled from 'styled-components';

const FourvenuesContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 20px;

  @media (max-width: 768px) {
    padding: 30px 10px; /* Redueix el padding per a pantalles petites */
  }
`;

const IframeWrapper = styled.div`
  width: 100%;
  max-width: 70vw;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    max-width: 90vw;
    height: auto; /* Ajusta l'alçada per assegurar que tot el contingut és visible */
  }
`;

const Fourvenues = () => {
  useEffect(() => {
    // Crear l'element script
    const script = document.createElement('script');
    script.src = "https://www.fourvenues.com/assets/iframe/royale-vilafranca/?theme=dark&amp;hide=padding,cover";
    script.async = true;

    // Inserir el script dins del contenidor
    const scriptContainer = document.getElementById('fourvenues-iframe');
    scriptContainer.appendChild(script);

    // Opcional: netejar el script en desmuntar el component
    return () => {
      scriptContainer.innerHTML = "";
    };
  }, []);

  return (
    <FourvenuesContainer>
      <IframeWrapper id="fourvenues-iframe">
        <div id="fourvenues-iframe-anchor" style={{ width: "100%", height: "0px", border: "0px", position: "absolute", top: "-50px" }}></div>
      </IframeWrapper>
    </FourvenuesContainer>
  );
};

export default Fourvenues;
