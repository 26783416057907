import React, { useState } from 'react';
import styled from 'styled-components';

// Estils per al formulari de contacte
const ContactFormContainer = styled.div`
  background-color: #222;
  color: white;
  padding: 40px 30px;
  width: 90%;
  max-width: 60%; /* Ample per a pantalles grans */
  margin: 40px auto;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    width: 100%; /* Ample per a pantalles petites */
    max-width: 80%;
    padding: 30px 20px;
    margin: 20px auto;
  }
`;

const FormTitle = styled.h3`
  margin-bottom: 25px;
  text-align: center;
  font-size: 1.8em;
  color: #25d366;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 20px;
  border: 2px solid transparent;
  border-radius: 8px;
  font-size: 1em;
  background-color: #333;
  color: white;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #25d366;
    outline: none;
    box-shadow: 0 0 8px rgba(37, 211, 102, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 10px;
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  margin-bottom: 20px;
  border: 2px solid transparent;
  border-radius: 8px;
  font-size: 1em;
  background-color: #333;
  color: white;
  resize: none;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #25d366;
    outline: none;
    box-shadow: 0 0 8px rgba(37, 211, 102, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 10px;
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #1ebe57;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 12px;
  }
`;

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:info@royalegrup.cat?subject=Missatge de ${formData.name}&body=${formData.message}`;
    window.location.href = mailtoLink;
  };

  return (
    <ContactFormContainer>
      <FormTitle>Vols treballar amb nosaltres?</FormTitle>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="El teu nom"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="El teu correu electrònic"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextArea
          name="message"
          rows="5"
          placeholder="El teu missatge"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <Button type="submit">Envia</Button>
      </Form>
    </ContactFormContainer>
  );
};

export default ContactForm;
